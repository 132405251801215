import * as types from 'constants/actions';

export const addOrder = (order) => ({
  type: types.ADD_ORDER,
  order,
});

export const deleteOrder = (id) => ({
  type: types.DELETE_ORDER,
  id,
});

export const updateOrder = (id, order) => ({
  type: types.UPDATE_ORDER,
  id,
  order,
});

export const purchaseOrder = (id, order, email) => ({
  type: types.PURCHASE_ORDER,
  id,
  order,
});

export const getOrders = () => ({
  type: types.GET_ORDERS,
});

export const setOrders = (orders) => ({
  type: types.SET_ORDERS,
  orders,
});

export const getUserOrders = () => ({
  type: types.GET_USER_ORDERS,
});

export const setUserOrders = (orders) => ({
  type: types.SET_USER_ORDERS,
  orders,
});
