/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Rating } from 'primereact/rating';

import Cookies from 'js-cookie';
import _ from 'lodash';
import firebase from 'firebase/app';

import GeneralTab from 'components/detailed-tour-tabs/General';
import ReviewsTab from 'components/detailed-tour-tabs/Reviews';
import BookContainer from 'components/floating-components/BookContainer';
import BookLogin from 'components/floating-components/BookLogin';
import BookForm from 'components/forms/booking/BookForm';
import TermAndCondition from 'components/layout/TermAndCondition';

import { getComments, addComment } from '../../services/comments/actions';
import { addOrder } from '../../services/orders/actions';
import { getSingleTour, rateTour } from '../../services/tours/actions';
import { checkoutOrder } from '../../services/payment/actions';

import { commentDate, formatTime } from '../../helpers/dateTime';
import { checkIsLoggedIn } from '../../helpers/authentication';
import renderSchedSwitch from '../../helpers/renderSchedSwitch';
import { getDisabledDays, getSpecificDates } from '../../helpers/dateFormat';

import 'animate.css/animate.min.css';
import { ImageComponent } from '../../components/utils/ImageComponent';
import { IMAGE_DISPLAY } from '../../helpers/image';
// import moment from 'moment';

export class Tour extends Component {
  state = {
    visible: false,
    comment: null,
    page: 1,
    isprivate: false,
    rate: null,
    hideRate: false,
    openTerms: false,
    openMeals: false,
    date: null,
    adult: 0,
    child: 0,
    cost: 0,
    originalCost: 0,
    firstname: null,
    lastname: null,
    phone: null,
    pickup: null,
    email: null,
    language: null,
  };

  componentDidMount() {
    const {
      getSingleTour,
      match: {
        params: { tourId },
      },
    } = this.props;
    const tourid = tourId.split('-').pop();
    if (tourid) getSingleTour(tourid);
  }

  toggleMeals = (status) => {
    this.setState({ openMeals: status });
  };

  filterImages() {
    const { tour, images } = this.props;
    const photos = [];
    // eslint-disable-next-line no-unused-expressions
    tour &&
      !_.isEmpty(tour.photos) &&
      tour.photos.forEach((photo) => {
        // eslint-disable-next-line no-unused-expressions
        images?.length !== 0 &&
          photos.push(images?.filter((image) => image.uuid === photo)[0]);
      });

    return photos;
  }

  getImageDisplay() {
    const { tour, images } = this.props;
    const displayImage =
      tour && images?.filter((image) => image.uuid === tour.displayimage)[0];
    const tourImages = this.filterImages();

    if (displayImage) {
      return displayImage;
    }
    if (tourImages && tourImages[0]) {
      return tourImages[0];
    }
    return null;
  }

  getRating = () => {
    const { tour } = this.props;
    let rating = 0;
    const allTourRating =
      tour && tour.rate ? tour.rate.filter((item) => item.confirmed) : [];
    allTourRating.forEach((item) => {
      rating += item.rate;
    });
    return rating / allTourRating.length;
  };

  getTourComments = () => {
    const { comments, tour } = this.props;
    const tourComments =
      (comments &&
        tour &&
        comments.filter(
          (comment) => comment.tourid === tour.uuid && comment.confirmed,
        )) ||
      [];
    return tourComments;
  };

  getRestaurantInfo = (id) => {
    const { restaurants } = this.props;
    return restaurants.filter((restaurant) => restaurant.uuid === id)[0] || [];
  };

  // END ----------------------------------------------------------------------------------------------------

  closeDialog() {
    const { destroy } = this.props;
    this.setState({
      visible: false,
      page: 1,
      date: null,
      adult: 0,
      child: 0,
      cost: 0,
      originalCost: 0,
      firstname: null,
      lastname: null,
      phone: null,
      pickup: null,
      email: null,
    });
    destroy('booking');
  }

  addUserComment = () => {
    const {
      addComment,
      match: {
        params: { tourId },
      },
      getComments,
    } = this.props;
    const { comment, rate } = this.state;
    const tourid = tourId.split('-').pop();

    if (rate) {
      this.submitRate(rate);
    }

    const newComment = {
      comment,
      confirmed: false,
      tourid,
      timeadded: firebase.firestore.Timestamp.fromDate(new Date()),
      user: Cookies.get('user'),
    };
    addComment(newComment);
    this.setState({ comment: '' });
    getSingleTour(tourid);
    getComments();
  };

  getUserinfo = (id) => {
    const { user } = this.props;
    return user || null;
  };

  getMillis = (time) => (time ? time.toDate() : null);

  submitRate = (rate) => {
    this.setState({ rate });
    const {
      tour,
      rateTour,
      match: {
        params: { tourId },
      },
    } = this.props;
    const tourid = tourId.split('-').pop();
    const currentRate = {
      rate,
      timeadded: firebase.firestore.Timestamp.now(),
      user: Cookies.get('user'),
      confirmed: false,
    };
    const tourRate = tour.rate ? tour.rate : [];
    tourRate.push(currentRate);
    rateTour(tourid, tourRate);
    this.setState({ hideRate: true });
  };

  openDialog = () => {
    // const { tour } = this.props;
    // const currentTime = moment(new Date(), 'HH:mm').format('HH:mm');
    // const tourStart = moment(`${tour.schedule.start}`, 'HH:mm').format('HH:mm');
    // const diff = moment.utc(`${currentTime}`, 'HH:mm').diff(moment.utc(`${tourStart}`, 'HH:mm'), 'hours');
    // if (diff < 12) {
    //   alert('Cannot book a tour at this time');
    //   return;
    // }
    this.setState({
      visible: true,
    });
  };

  nextPage = (page) => {
    this.setState({ page });
  };

  onSubmit = (values) => {
    const {
      addOrder,
      checkoutOrder,
      match: {
        params: { tourId },
      },
      tour,
      history,
      checkout,
    } = this.props;
    const { isprivate, language } = this.state;

    const { agent } = this.getUserinfo(Cookies.get('user'));

    // if (!isprivate && !agent) {
    //   return;
    // }

    for (let x = 0; x < _.keys(tour).length; x++) {
      if (tour[Object.keys(tour)[x]] === undefined) {
        delete tour[Object.keys(tour)[x]];
        x -= 1;
      }
    }

    const body = {
      amount: values.amount,
      date: values.date,
      isprivate,
      leadpassenger: {
        fullname: {
          firstname: values.firstname,
          lastname: values.lastname,
        },
        phone: values.phone,
        pickup: values.pickup || null,
        email: values.email,
        request: values.request !== undefined ? values.request : '',
      },
      pickup: values.pickup || null,
      // ordered: firebase.firestore.Timestamp.fromDate(new Date()),
      lang: language,
      originalamount: values.originalamount,
      personcount: values.personcount,
      ref: String(Math.round(Math.random() * 10000000)),
      tourName: (tour && tour.name) || null,
      tour,
      tourid: tourId.split('-').pop(),
      user: Cookies.get('user'),
      type:
        this.getUserinfo(Cookies.get('user')) !== undefined &&
        !this.getUserinfo(Cookies.get('user')).agent
          ? !isprivate
            ? 'purchase'
            : 'book'
          : 'book',
    };

    if (isprivate) {
      delete body.personcount;
      delete body.amount;
      delete body.originalamount;
      delete body.checkout;
    }

    if (isprivate || agent) {
      addOrder(body);
      history.push(
        this.getUserinfo(Cookies.get('user')).agent ? '/dashboard' : '/order',
      );
      this.closeDialog();
    } else {
      checkoutOrder(body);
    }
    this.setState({ isprivate: false });
  };

  checkDoneTour = () => {
    const { orders, tour } = this.props;
    const userOrders = orders
      ? orders.sort(
          (a, b) => a.ordered.toDate().getTime() - b.ordered.toDate().getTime(),
        )
      : [];
    let status = false;
    for (const order of userOrders) {
      if (
        tour &&
        tour.uuid === order.tourid &&
        order.date.toDate().getTime() < new Date().getTime()
      ) {
        status = true;
        break;
      }
    }
    return status;
  };

  checkDoneRating = () => {
    const { tour } = this.props;
    let status = false;
    if (tour && tour.rate) {
      for (const rate of tour.rate) {
        if (rate.user === Cookies.get('user')) {
          status = true;
        }
      }
    }
    return status;
  };

  toggleTerms = (status) => {
    this.setState({ openTerms: status });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    const { tour, user, users, history, pickups } = this.props;

    const {
      openMeals,
      hideRate,
      isprivate,
      page,
      visible,
      rate,
      comment,
      openTerms,
      date,
      adult,
      child,
      cost,
      originalCost,
      firstname,
      lastname,
      pickup,
      phone,
      email,
      language,
    } = this.state;

    const tourImages = this.filterImages();
    return tour ? (
      <div className="container tour-details-body">
        <div className="row">
          <div className="tour-details-container">
            <div className="col">
              <div className="row mb-2">
                <div className="col">
                  <h2>
                    <label>{tour.name}</label>
                  </h2>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-3 d-flex justify-content-between">
                  <Rating cancel={false} value={this.getRating()} readonly />
                  <span className="fade-gray">
                    {`${this.getTourComments()?.length} Reviews`}
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <ImageComponent
            alt=""
            height={500}
            width="100%"
            type={IMAGE_DISPLAY}
            image={this.getImageDisplay()}
            className="image-header"
          />
        </div>
        <div className="row section" style={{ marginBottom: '300px' }}>
          <div className="col">
            <TabView className="no-border-tabs">
              <TabPanel header="General">
                <GeneralTab
                  lightRoomImages={tourImages}
                  getRestaurantInfo={this.getRestaurantInfo}
                  toggleMeals={this.toggleMeals}
                  tours={tour}
                />
              </TabPanel>
              <TabPanel header="Reviews">
                <ReviewsTab
                  tourComments={this.getTourComments()}
                  rate={rate}
                  comment={comment}
                  users={users}
                  hideRate={hideRate}
                  checkDoneTour={() => this.checkDoneTour()}
                  checkDoneRating={() => this.checkDoneRating()}
                  setRate={(value) => this.setState({ rate: value })}
                  setComment={(value) => this.setState({ comment: value })}
                  addUserComment={() => this.addUserComment()}
                  commentDate={commentDate}
                />
              </TabPanel>
            </TabView>
          </div>
        </div>
        {/* <div className="row">
          <div className="tour-details-container">
            <div className="col">
              <div className="row mb-2">
                <div className="col">
                  <h2>
                    <label>{tour.name}</label>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 d-flex justify-content-between">
                  <Rating cancel={false} value={this.getRating()} readonly />
                  <span className="fade-gray">
                    {`${this.getTourComments()?.length} Reviews`}
                  </span>
                </div>
              </div>

              <div className="row section">
                <div className="col">
                  <span className="fade-gray">{tour.type}</span>
                  <br />
                  <span className="fade-gray">
                    {`${formatTime(tour.schedule.start)} - ${formatTime(
                      tour.schedule.end,
                    )}`}
                  </span>
                </div>
              </div>
              <div className="row section">
                <div className="col">
                  <span className="fade-gray-description">{tour.description}</span>
                </div>
              </div>
              <div className="row section" style={{ marginBottom: '300px' }}>
                <div className="col">
                  <TabView className="no-border-tabs">
                    <TabPanel header="General">
                      <GeneralTab
                        lightRoomImages={tourImages}
                        getRestaurantInfo={this.getRestaurantInfo}
                        toggleMeals={this.toggleMeals}
                      />
                    </TabPanel>
                    <TabPanel header="Reviews">
                      <ReviewsTab
                        tourComments={this.getTourComments()}
                        rate={rate}
                        comment={comment}
                        users={users}
                        hideRate={hideRate}
                        checkDoneTour={() => this.checkDoneTour()}
                        checkDoneRating={() => this.checkDoneRating()}
                        setRate={value => this.setState({ rate: value })}
                        setComment={value => this.setState({ comment: value })
                        }
                        addUserComment={() => this.addUserComment()}
                        commentDate={commentDate}
                      />
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {checkIsLoggedIn() ? (
          <BookContainer
            tour={tour}
            renderSchedSwitch={renderSchedSwitch}
            openDialog={() => this.openDialog()}
          />
        ) : (
          <BookLogin tour={tour} history={history} props={this.props} />
        )}
        <Dialog
          className="booking"
          visible={visible}
          showHeader
          dismissableMask={false}
          closeOnEscape={false}
          onHide={() => this.closeDialog()}
        >
          <BookForm
            type={tour.schedule.type}
            page={page}
            dateState={date}
            adultState={adult}
            childState={child}
            costState={cost}
            originalCostState={originalCost}
            firstname={firstname}
            lastname={lastname}
            phone={phone}
            pickup={pickup}
            email={email}
            price={tour.pricing}
            isprivate={isprivate}
            agent={user && user.agent}
            commission={user && user?.commission}
            onSubmit={this.onSubmit}
            nextPage={this.nextPage}
            setDateState={(date) => this.setState({ date })}
            setAdultState={(adult) => this.setState({ adult })}
            setChildState={(child) => this.setState({ child })}
            setCostState={(cost, originalCost) =>
              this.setState({ cost, originalCost })
            }
            setFirstname={(firstname) => this.setState({ firstname })}
            setLastname={(lastname) => this.setState({ lastname })}
            setPhone={(phone) => this.setState({ phone })}
            setEmail={(email) => this.setState({ email })}
            setPickup={(pickup) => this.setState({ pickup })}
            setLanguage={(language) => this.setState({ language })}
            setIsprivate={(isprivate) => this.setState({ isprivate })}
            disabledDays={
              tour.schedule.type !== 'Specific Dates' &&
              getDisabledDays(tour.schedule)
            }
            toggleTerms={this.toggleTerms}
            validateEmail={this.validateEmail}
            options={
              tour.schedule.type === 'Specific Dates' &&
              getSpecificDates(tour.schedule)
            }
            transportType={tour.transportType}
            pickups={pickups}
            tourPickups={tour.pickups}
            tour={tour.name}
            tours={tour}
            languages={tour.languages}
          />
        </Dialog>
        <Dialog
          header="Terms and Condition"
          visible={openTerms}
          onHide={() => this.toggleTerms(false)}
        >
          <TermAndCondition modal />
        </Dialog>
        <Dialog
          className="booking"
          visible={openMeals}
          onHide={() => this.toggleMeals(false)}
          showHeader={false}
          dismissableMask
        >
          <h3 className="font-weight-bold">
            {this.getRestaurantInfo(tour && tour.restaurant) &&
              this.getRestaurantInfo(tour.restaurant).name}
          </h3>
          <p style={{ whiteSpace: 'pre-line' }}>
            {this.getRestaurantInfo(tour && tour.restaurant) &&
              this.getRestaurantInfo(tour.restaurant).menu}
          </p>
        </Dialog>
      </div>
    ) : (
      <div className="container tour-details-body" />
    );
  }
}

export default connect(
  ({
    tours,
    gallery,
    orders,
    comments,
    users,
    restaurants,
    payments,
    pickups,
  }) => ({
    tour: tours.tour,
    images: gallery.images,
    orders: orders.userOrders,
    comments: comments.comments,
    users: users.users,
    user: users.user,
    restaurants: restaurants.restaurants,
    checkout: payments.checkout,
    pickups: pickups.pickups,
  }),
  {
    getSingleTour,
    rateTour,
    addOrder,
    getComments,
    addComment,
    checkoutOrder,
    destroy,
  },
)(Tour);
