import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { spinnerField } from 'helpers/reduxForms';
import BookHeader from 'components/forms/booking/BookHeader';
import BookFooter from 'components/forms/booking/BookFooter';
import { error } from 'helpers/reactToaster';
import AlertMessage from 'components/utils/AlertMessage';
import { ScrollPanel } from 'primereact/scrollpanel';
import moment from 'moment';
import _ from 'lodash';

export class CompleteOrderForm extends Component {
  limit = 7;

  state = {
    adult: null,
    child: 0,
  };

  componentDidMount() {
    const { adultState, childState } = this.props;
    this.setState({
      adult: adultState,
      child: childState,
    });
  }

  isAtLimit = (adult, child) => {
    // const {adult, child} = this.state;
    let status = false;
    if (adult + child > this.limit) {
      status = true;
    }
    return status;
  };

  retrieveAdult = (value) => {
    const { setAdultState } = this.props;
    let newValue = value;
    const { child } = this.state;
    if (this.isAtLimit(value, child)) {
      newValue = this.limit - child;
      error(
        'Tickets only limited to 7. Please contact admin if you wish to book more.',
      );
    }

    this.setState(
      {
        adult: newValue,
      },
      () => this.getCost(),
    );

    setAdultState(newValue);

    return newValue;
  };

  retrieveChild = (value) => {
    const { setChildState } = this.props;
    let newValue = value;
    const { adult } = this.state;
    if (this.isAtLimit(adult, value)) {
      newValue = this.limit - adult;
      error(
        'Tickets only limited to 7. Please contact admin if you wish to book more.',
      );
    }

    this.setState(
      {
        child: newValue,
      },
      () => this.getCost(),
    );

    setChildState(newValue);
    return newValue;
  };

  renderPrice = (prices, agent) => {
    const priceElement = prices.map((price, i) => (
      <div className="row" key={i}>
        <span className="d-flex align-items-center">
          <label style={{ marginBottom: 0 }}>{`${
            agent ? this.getDiscountedPrice(price.price) : price.price
          } €`}</label>
          <span className="ml-3">{`${price.from} to ${price.to} Persons`}</span>
        </span>
      </div>
    ));
    return priceElement;
  };

  getDiscountedPrice = (price) => {
    const { commission } = this.props;
    const discountedPrice = price - price * (commission / 100);
    return Math.round((discountedPrice + 0.00001) * 100) / 100;
  };

  getCost = () => {
    const { price, agent, setCostState } = this.props;
    const { adult, child } = this.state;

    let adultCost = 0;
    let childCost = 0;
    let adultOriginalCost = 0;
    let childOriginalCost = 0;
    let cost = 0;
    let originalCost = 0;

    if (agent) {
      adultCost =
        (price.type === 'tiered'
          ? this.applyAgentDiscount(this.applyDiscount('adult', agent))
          : this.applyAgentDiscount(price.price.adult)) * adult;
      childCost =
        (price.type === 'tiered'
          ? this.applyAgentDiscount(this.applyDiscount('child', child))
          : this.applyAgentDiscount(price.price.child)) * child;
      cost = adultCost + childCost;
    } else {
      adultCost =
        (price.type === 'tiered'
          ? this.applyDiscount('adult', agent)
          : price.price.adult) * adult;
      childCost =
        (price.type === 'tiered'
          ? this.applyDiscount('child', agent)
          : price.price.child) * child;
      cost = adultCost + childCost;
    }

    adultOriginalCost =
      (price.type === 'tiered'
        ? this.applyDiscount('adult', agent)
        : price.price.adult) * adult;
    childOriginalCost =
      (price.type === 'tiered'
        ? this.applyDiscount('child', agent)
        : price.price.child) * child;
    originalCost = adultOriginalCost + childOriginalCost;

    setCostState(cost, originalCost);
  };

  onSubmit = (values) => {
    const { onSubmit, setCost, cost, originalCost } = this.props;
    // const {cost, originalCost} = this.state;
    values.amount = cost;
    values.originalamount = originalCost;
    setCost(cost);
    onSubmit(values);
  };

  applyDiscount = (person) => {
    const { price, date } = this.props;
    const oneDay = 1000 * 60 * 60 * 24;
    const today = new Date().getTime();
    const dateUnix = _.get(date, 'seconds');
    const formattedDateSchedule = moment.unix(dateUnix).valueOf();

    const schedule = formattedDateSchedule;
    const dayDiffer = Math.round((schedule - today) / oneDay);
    const defaultPrice =
      person === 'adult' ? price.price.adult : price.price.child;
    let discountPrice = defaultPrice;

    for (const discount of price.discount.filter(
      (discount) => discount.active,
    )) {
      if (discount.days >= dayDiffer) {
        const discountPercent =
          (person === 'adult'
            ? discount.discount.adult
            : discount.discount.child) / 100;
        discountPrice = defaultPrice - defaultPrice * discountPercent;
        break;
      }
    }

    return discountPrice;
  };

  applyAgentDiscount = (price) => {
    const { commission } = this.props;
    const discountPrice = price - price * (commission / 100);

    return discountPrice;
  };

  render() {
    const {
      handleSubmit,
      price,
      agent,
      onPrevious,
      adultState,
      cost,
      originalCost,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <BookHeader page={2} title="Complete Order" />
        <ScrollPanel style={{ maxHeight: '500px' }}>
          <div className="row" style={{ padding: '10px 50px' }}>
            <div className="col">
              <div className="row section">
                <div className="col d-flex justify-content-between person-header border-bottom-header pb-2">
                  <span>ADULT</span>
                  <span className="fade-gray">(from 11 years)</span>
                </div>
              </div>
              <div className="row section">
                <div className="col d-flex justify-content-between">
                  <span>
                    <span className="d-flex align-items-center">
                      <label style={{ marginBottom: 0 }}>
                        {`€ ${Number(
                          price.type === 'tiered'
                            ? this.applyDiscount('adult')
                            : price.price.adult,
                        ).toFixed(2)}`}
                      </label>
                      <span className="ml-3">per person</span>
                    </span>
                  </span>
                  <span>
                    <Field
                      name="personcount.adult"
                      normalize={this.retrieveAdult}
                      component={spinnerField}
                      min={1}
                    />
                    <br />
                    {!adultState && (
                      <AlertMessage message="At least 1 adult is required" />
                    )}
                  </span>
                </div>
              </div>
              <div className="row section">
                <div className="col d-flex justify-content-between person-header border-bottom-header pb-2">
                  <span>Child</span>
                  <span className="fade-gray">(from 5 to 10 years)</span>
                </div>
              </div>
              <div className="row section">
                <div className="col d-flex justify-content-between">
                  <span>
                    <span className="d-flex align-items-center">
                      <label style={{ marginBottom: 0 }}>
                        {`€ ${Number(
                          price.type === 'tiered'
                            ? this.applyDiscount('child')
                            : price.price.child,
                        ).toFixed(2)}`}
                      </label>
                      <span className="ml-3">per person</span>
                    </span>
                  </span>
                  <Field
                    name="personcount.child"
                    normalize={this.retrieveChild}
                    component={spinnerField}
                    min={0}
                  />
                </div>
              </div>
              <div className="row section">
                <div className="col d-flex justify-content-between person-header border-bottom-header pb-2">
                  <span>INFANT</span>
                  <span className="fade-gray">(from 1 to 4 years)</span>
                </div>
              </div>
              <div className="row section">
                <div className="col d-flex justify-content-between">
                  <span className="d-flex align-items-center">
                    <label style={{ marginBottom: 0 }}>€ 0</label>
                    <span className="ml-3">per person</span>
                  </span>
                  <Field
                    name="personcount.infant"
                    component={spinnerField}
                    min={0}
                  />
                </div>
              </div>
              <div className="row section">
                <div className="col d-flex justify-content-between person-header border-bottom-header pb-2" />
              </div>
              <div className="row section">
                <div className="col d-flex justify-content-between">
                  <label>Total</label>
                  <span className="theme-red">{`€ ${
                    cost ? Number(agent ? originalCost : cost).toFixed(2) : 0
                  }`}</span>
                </div>
              </div>
            </div>
          </div>
        </ScrollPanel>
        <BookFooter
          page={2}
          onPrevious={onPrevious}
          disabled={adultState < 1}
          agent={agent}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'booking', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CompleteOrderForm);
