import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card } from 'primereact/card';
import { textField } from 'helpers/reduxForms';
import { windowHeight } from 'constants/height';

export class ForgotPasswordForm extends Component {
  onSubmit = (values) => {
    const { onSubmit, destroy } = this.props;
    console.log(values);
    destroy();
    onSubmit(values);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container" style={{ minHeight: windowHeight + 30 }}>
        <div className="row">
          <div className="col-lg-4 offset-lg-4" style={{ marginTop: 150 }}>
            <Card>
              <div className="row">
                <div className="col border-bottom mb-3 text-center">
                  <h2>Reset Password</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                  <div className="col">
                    <Field name="email" component={textField} label="Email" />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-secondary btn-lg btn-block"
                    >
                      Send Email
                    </button>
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'REQUIRED_EMAIL';
  } else {
    const regex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(values.email).toLowerCase())) {
      errors.email = 'INVALID_EMAIL';
    }
  }

  return errors;
};

export default reduxForm({
  form: 'forgotPasswordForm',
  validate,
})(ForgotPasswordForm);
