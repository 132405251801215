export const HOME = '/';
export const TOUR = '/tour/:tourId';
export const MESSAGE = '/message/:message';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const FORGOT_PASSWORD = '/forgotPassword';
export const RESET_PASSWORD = '/resetPassword';
export const ORDER = '/order';
export const ORDERSUMMARY = '/order/ordersummary';
export const DASHBOARD = '/dashboard';
export const UPDATE_PROFILE = '/updateProfile';
export const TOURS = '/tours';
export const TOURS_FEATURED = '/tours/featured';
export const VERIFY_EMAIL = '/verifyEmail';
export const AUTH = '/auth';
export const PRINT_ORDER = '/print';

// FOOTER ITEMS
export const PRIVACY_POLICY = '/privacy-policy';
export const CANCELLATION_POLICY = '/cancellation-policy';
export const TERMS = '/terms';
export const CONTACT_US = '/contact-us';
export const ABOUT_US = '/about-us';
