import {
  SET_CHECKOUT,
  PREPARE_CHECKOUT,
  GET_PAYMENT_STATUS,
  SET_PAYMENT_STATUS,
  SET_CHECKOUT_ORDER,
  SET_LOADING,
} from './actions';

const initialState = {
  checkout: null,
  loading: false,
  status: null,
  checkoutOrder: null,
};

export default (state = initialState, action) => {
  const { type, checkout, status, order } = action;

  switch (type) {
    case PREPARE_CHECKOUT:
      return { ...state, loading: true };
    case SET_CHECKOUT:
      return { ...state, checkout, loading: false };
    case GET_PAYMENT_STATUS:
      return { ...state, loading: true };
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        status,
        loading: false,
        checkout: null,
        checkoutOrder: null,
      };
    case SET_CHECKOUT_ORDER:
      return { ...state, checkoutOrder: order, loading: false };
    case SET_LOADING:
      return { ...state, loading: false, checkout: null };
    default:
      return state;
  }
};
