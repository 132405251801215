import * as types from 'constants/actions';

const initState = {
  comments: null,
};

export default (state = initState, action) => {
  const { comments, type } = action;

  switch (type) {
    case types.SET_COMMENTS:
      return { ...state, comments };
    default:
      return { ...state };
  }
};
