import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card } from 'primereact/card';
import { textField, passwordField, calendarField } from 'helpers/reduxForms';
import { error } from 'helpers/reactToaster';
import { windowHeight } from 'constants/height';

export class ProfileForm extends Component {
  onSubmit = (values) => {
    const { onSubmit, initialValues } = this.props;

    // eslint-disable-next-line no-unused-expressions
    values.email !== initialValues.email
      ? values.oldpassword !== undefined
        ? onSubmit(values)
        : error('Please enter password on Old Password before updating email')
      : onSubmit(values);
  };

  render() {
    const { handleSubmit, deleteProfile } = this.props;
    return (
      <div className="container" style={{ minHeight: windowHeight + 30 }}>
        <div className="row">
          <div className="col">
            <Card className="mt-3 animated pulse">
              <div className="row">
                <div className="col">
                  <h3>My Profile</h3>
                </div>
              </div>
              <hr />
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                  <div className="col">
                    <Field
                      name="firstname"
                      component={textField}
                      label="First Name"
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="lastname"
                      component={textField}
                      label="Last Name"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <Field name="email" component={textField} label="Email" />
                  </div>
                  <div className="col">
                    <Field
                      name="dob"
                      component={calendarField}
                      label="Date of Birth"
                      monthNavigator
                      yearNavigator
                      yearRange="1967:2030"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <Field
                      name="oldpassword"
                      component={passwordField}
                      label="Old Password"
                      feedback={false}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name="newpassword"
                      component={passwordField}
                      label="New Password"
                      feedback={false}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6" />
                  <div className="col-3">
                    {/* <button onClick={deleteProfile} style={{marginTop: 30}} className="btn btn-danger btn-lg btn-block">Delete</button> */}
                  </div>
                  <div className="col-3">
                    <button
                      type="submit"
                      style={{ marginTop: 30 }}
                      className="btn btn-danger btn-lg btn-block"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'REQUIRED_EMAIL';
  } else {
    const regex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(values.email).toLowerCase())) {
      errors.email = 'INVALID_EMAIL';
    }
  }

  return errors;
};

export default reduxForm({
  form: 'profileForm',
  validate,
})(ProfileForm);
