import * as types from 'constants/actions';

const initState = {
  guides: [],
  guide: null,
};

export default (state = initState, action) => {
  const { guide, guides, type } = action;

  switch (type) {
    case types.SET_GUIDES:
      return { ...state, guides };
    case types.SET_SINGLE_GUIDE:
      return { ...state, guide };
    default:
      return { ...state };
  }
};
