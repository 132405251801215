import React, { Component } from 'react';
import ContactForm from 'components/forms/ContactForm';
import axios from 'axios';
import { success, error } from 'helpers/reactToaster';
import { MALTATOURS_CONFIG } from '../firebase-service/config';

export class Contact extends Component {
  sendMessage = (data) => {
    // const { fullname, email, message} = data;
    // mail.contactFormMail({
    //   fullname,
    //   from: email,
    //   to: 'maltatourtest@gmail.com',
    //   message
    // });
    axios
      .post(`${MALTATOURS_CONFIG.cf}/sendMessageEmail`, { data })
      .then((res) =>
        res.status === 200
          ? success(
              'Thanks for contacting us! We will get back to you as soon as we can!',
            )
          : error(res.data),
      );
  };

  render() {
    return <ContactForm onSubmit={this.sendMessage} />;
  }
}

export default Contact;
