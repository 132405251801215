export const IMAGE_DISPLAY = '_1500x1500';
export const IMAGE_THUMBNAIL = '_200x200';

function prefixImage(image, type) {
  const filename = image?.name.split('.').slice(0, -1).join('.');
  const imagePrefix = `${filename}${type}`;
  const encoded = encodeURIComponent(filename);
  const prefixedURL = image?.url.replace(encoded, imagePrefix);
  return prefixedURL;
}

export { prefixImage };
