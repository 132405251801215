import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { Card } from 'primereact/card';
import PickupPoint from 'components/layout/PickupPoint';
import { withRouter } from 'react-router';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button } from 'primereact/button';
import { windowHeight } from 'constants/height';
import { getCurrentUser, getUsers } from '../services/users/actions';
import { getPickups } from '../services/pickups/actions';
import { getImages } from '../services/gallery/actions';
import { getOrders, getUserOrders } from '../services/orders/actions';
import { getTours } from '../services/tours/actions';
import { formatTime } from '../helpers/dateTime';
import 'animate.css/animate.min.css';
import { checkIsLoggedIn } from '../helpers/authentication';
import { ImageComponent } from '../components/utils/ImageComponent';
import { IMAGE_DISPLAY } from '../helpers/image';

export class Order extends Component {
  componentDidMount() {
    const { getUserOrders, history } = this.props;
    if (!checkIsLoggedIn()) {
      history.push('/');
    }
    getUserOrders();
  }

  getTourItem = (tourId) => {
    const { tours } = this.props;
    const tourItem = tours
      ? tours.filter((tour) => tour.uuid === tourId)[0]
      : null;
    return tourItem;
  };

  getImageUrl(id) {
    const { images } = this.props;
    const image =
      images.length > 0 && images.filter((image) => image.uuid === id)[0]
        ? images.filter((image) => image.uuid === id)[0]
        : null;
    return image;
  }

  getDates = (date) => {
    let dates = null;
    try {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      dates = `${days[date.toDate().getDay()]}, ${date.toDate().getDate()} ${
        months[date.toDate().getMonth()]
      } ${date.toDate().getFullYear()}`;
    } catch (e) {
      console.log(e);
    }
    return dates;
  };

  getUserinfo = (id) => {
    const { user } = this.props;
    return user || null;
  };

  getTourInfo = (id) => {
    const { tours } = this.props;
    return tours ? tours.filter((tour) => tour.uuid === id)[0] : null;
  };

  getPickupInfo = (id) => {
    const { pickups } = this.props;
    return pickups ? pickups.filter((pickup) => pickup.uuid === id)[0] : null;
  };

  onPrint = (item) => {
    const { history } = this.props;
    history.push('/print', { data: item });
  };

  getOrders = (isAgent) => {
    const { orders } = this.props;
    return orders
      ? orders
          .filter((order) => {
            if (isAgent || order.isprivate) {
              return order.user === Cookies.get('user');
            }

            return order.user === Cookies.get('user') && order.payment;
          })
          .sort(
            (a, b) =>
              b.ordered.toDate().getTime() - a.ordered.toDate().getTime(),
          )
      : [];
  };

  getSelectedPickup = (pickup, tour) => {
    const { pickups } = this.props;
    const tourPickup = tour.pickups.find((p) => p.id === pickup);
    const pickupPoint = pickups.find((p) => p.uuid === pickup);

    if (tourPickup && pickupPoint) {
      return (
        <div>
          You will be picked up from {pickupPoint.name} at {tourPickup.time}.
        </div>
      );
    }
    return '';
  };

  render() {
    const { user } = this.props;
    const isAgent = user && user.agent;
    const userOrder = this.getOrders(isAgent);
    return (
      <div className="container" style={{ minHeight: windowHeight + 30 }}>
        <div className="row">
          <div className="col">
            <h1>{isAgent ? 'Dashboard' : 'Your Orders'}</h1>
          </div>
        </div>
        {userOrder.length > 0 ? (
          userOrder.map((item, i) => (
            <div className="row mt-3" key={i}>
              <div className="col">
                <ScrollAnimation animateIn="fadeInUp" animateOnce>
                  <Card style={{ borderRaduis: '20px' }}>
                    <div className="row">
                      <div className="col">
                        <Button
                          type="button"
                          label="Print"
                          icon="pi pi-print"
                          className="p-button-info mb-2"
                          onClick={() => this.onPrint(item)}
                        />
                        <div className="row justify-content-md-center">
                          <div className="col-offset-lg-3 col-lg-6 text-center">
                            <ImageComponent
                              alt=""
                              height={140}
                              width="100%"
                              type={IMAGE_DISPLAY}
                              image={this.getImageUrl(
                                (this.getTourItem(item.tourid) &&
                                  this.getTourItem(item.tourid).displayimage) ||
                                  (this.getTourItem(item.tourid) &&
                                    this.getTourItem(item.tourid).photos[0]),
                              )}
                              className="order-header"
                            />
                          </div>
                        </div>
                        <div className="row justify-content-md-center">
                          <div className="col-offset-lg-3 col-lg-6">
                            <div className="tour-details-container">
                              <div className="row">
                                <div className="col">
                                  <h2>
                                    <label>{item.tour && item.tour.name}</label>
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="row justify-content-md-center"
                                style={{ marginTop: '30px' }}
                              >
                                <div className="col text-center">
                                  <h2>
                                    <label className="theme-red">
                                      {item.ref}
                                    </label>
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: '30px' }}
                              >
                                <div className="col text-center">
                                  <h4 className="theme-red">
                                    YOUR ORDER NUMBER
                                  </h4>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col d-flex justify-content-between">
                                  <span>{this.getDates(item.date)}</span>
                                  <span>
                                    {this.getTourInfo(item.tourid) &&
                                      `${formatTime(
                                        this.getTourInfo(item.tourid).schedule
                                          .start,
                                      )} - ${formatTime(
                                        this.getTourInfo(item.tourid).schedule
                                          .end,
                                      )}`}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <hr />
                                </div>
                              </div>
                              {item.cancelled ? (
                                <div className="row">
                                  <div className="col text-center">
                                    <h1 className="fade-gray font-weight-bold">
                                      CANCELLED
                                    </h1>
                                  </div>
                                </div>
                              ) : (
                                <Fragment>
                                  {!item.isprivate ? (
                                    <div className="row">
                                      <div className="col d-flex justify-content-between">
                                        <span>Number of people</span>
                                        <span>
                                          {item.personcount.adult +
                                            (item.personcount.child || 0)}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <div className="col text-center">
                                        <span className="font-weight-bold">
                                          TOUR IS PRIVATE
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <div className="row">
                                    <div className="col">
                                      <hr />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <span className="font-weight-bold">
                                        Transportation
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    {item.tour.transportType ===
                                      'no-transport' && (
                                      <div>No transport is available.</div>
                                    )}
                                    {item.tour.transportType ===
                                      'transport-included-in-price' &&
                                      this.getSelectedPickup(
                                        item.pickup,
                                        item.tour,
                                      )}
                                    {item.tour.transportType ===
                                      'transport-optional' &&
                                      !item.pickup && (
                                        <div>You opted for no transport.</div>
                                      )}
                                    {item.tour.transportType ===
                                      'transport-optional' &&
                                      item.pickup &&
                                      this.getSelectedPickup(
                                        item.pickup,
                                        item.tour,
                                      )}
                                  </div>
                                  {/* <PickupPoint
                                    tour={item.tour}
                                    getPickupInfo={this.getPickupInfo}
                                    formatTime={formatTime}
                                  /> */}
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </ScrollAnimation>
              </div>
            </div>
          ))
        ) : (
          <div className="row">
            <div className="col text-center">
              <span>
                You don't have any orders yet. Click <a href="/">here</a> to
                view our large selection of tours.
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ tours, gallery, orders, users, pickups }) => ({
      tours: tours.tours,
      images: gallery.images,
      orders: orders.userOrders,
      user: users.user,
      pickups: pickups.pickups,
    }),
    {
      getTours,
      getImages,
      getOrders,
      getUsers,
      getPickups,
      getCurrentUser,
      getUserOrders,
    },
  )(Order),
);
