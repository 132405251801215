import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { windowHeight } from 'constants/height';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { getPaymentStatus } from '../services/payment/actions';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Divider = () => (
  <div className="row">
    <div className="col">
      <hr />
    </div>
  </div>
);

export const Spinner = () => (
  <div className="d-flex justify-content-center mt-5">
    <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export const DetailSummary = ({title, detail}) => (
  <div className="col">
    <div className="row">
      <div className="col d-flex justify-content-between">
        <span><strong>{title}</strong></span>
        <span>{detail}</span>
      </div>
    </div>
  </div>
);

export const OrderSummary: React.FC = () => {
  const { status, loading } = useSelector(state => state.payments);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const query = useQuery();
  const resourPath = query.get('resourcePath');
  const checkoutid = query.get('id');
  const dispatch = useDispatch();
  const history = useHistory();
  const successTransactionRegEx = /^(000\.000\.|000\.100\.1|000\.[36])/;

  const handleBtnPress = (path) => {
    history.push(path);
  };

  const validatePayment = () => {
    if (status && _.has(status, 'statusCode')) {
      setHasError(true);
    }

    if (status && !successTransactionRegEx.test(status.result && status.result.code)) {
      setHasError(true);
    }
    setLoading(loading);
  };
  useEffect(() => {
    if (resourPath && checkoutid) {
      dispatch(getPaymentStatus(resourPath, checkoutid));
    }
  }, [resourPath, checkoutid, dispatch]);

  useEffect(validatePayment, [status, loading]);
  return (
    <div className="container order-summary" style={{ minHeight: windowHeight + 30 }}>
      { resourPath && (
        <div className="row mt-3">
          <div className="col">
            {isLoading ? <Spinner /> : (
              <React.Fragment>
                <div className="row justify-content-md-center" style={{ marginTop: '30px' }}>
                  <div className="col text-center">
                    {!hasError ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: '#28a745', fontSize: '40px' }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        style={{ color: '#dc3545', fontSize: '40px' }}
                      />
                    )
                    }
                    {!hasError ? (
                      <h1 className="h1 text-success">PAYMENT SUCCESSFUL</h1>
                    ) : <h1 className="h1 text-danger">OOPS! SOMETHING WENT WRONG</h1>
                    }
                  </div>
                </div>
                <div className="row section justify-content-md-center">
                  <div className="col text-center">
                    <p className="lead" style={{ fontSize: 16 }}>
                      {!hasError ? 'Your order is successful. You can now view your order.' : 'Payment failed. We could not complete transaction.'
                      }
                    </p>
                  </div>
                </div>
                {!hasError && (
                  <div className="row container justify-content-md-center py-5" style={{ marginTop: '50px' }}>
                    <div className="col col-offset-lg-3 col-lg-6">
                      <DetailSummary title="Amount Paid" detail={`€ ${status && Number(status.amount).toFixed(2)} `} />
                      <Divider />
                      <DetailSummary title="Payment Method" detail={`${status && status.paymentBrand} ${status && status.card.last4Digits}`} />
                      <Divider />
                      <DetailSummary title="Order ID" detail={status && status.orderRef} />
                      <Divider />
                      <DetailSummary title="Tour Name" detail={status && status.tourname} />
                    </div>
                  </div>
                )}
                <div className="row justify-content-md-center text-center" style={{ marginTop: '50px' }}>
                  <div className="col col-offset-lg-3 col-lg-6 d-flex justify-content-around">
                    <button type="button" className="p-button p-component red-button p-button-text-only" onClick={() => handleBtnPress('/order')}>
                      <span>VIEW ORDERS</span>
                    </button>
                    <button type="button" className="p-button p-component p-button-text-only with-outline" onClick={() => handleBtnPress('/')}>
                      <span>GO TO DASHBOARD</span>
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
