import * as types from 'constants/actions';

export const getImages = () => ({
  type: types.GET_IMAGES,
});

export const setImages = (images) => ({
  type: types.SET_IMAGES,
  images,
});
