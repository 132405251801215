export const getDisabledDays = (schedule) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  let disabledDays = [0, 1, 2, 3, 4, 5, 6];

  disabledDays =
    schedule.type === 'Everyday'
      ? null
      : disabledDays.filter((day) => !schedule.days.includes(days[day]));

  return disabledDays;
};

export const getSpecificDates = (schedule) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const specificDates = [];

  schedule.dates
    .sort((a, b) => a.toDate().getTime() - b.toDate().getTime())
    .forEach((date) => {
      if (new Date().getTime() <= date.toDate().getTime()) {
        specificDates.push({
          label: `${months[date.toDate().getMonth()]} ${date.toDate().getDate()}, ${date.toDate().getFullYear()}`,
          value: date.toDate(),
        });
      }
    });

  if (specificDates.length < 1) {
    specificDates.push({
      label: 'All schedules have already ended',
      value: false,
    });
  }

  return specificDates;
};
