export const TOUR = [
  'transport',
  'pickups',
  'entrance',
  'accessibility',
  'active',
  'cancellation',
  'code',
  'description',
  'displayimage',
  'featured',
  'guide',
  'languages',
  'message',
  'name',
  'orders',
  'places',
  'photos',
  'pricing',
  'rate',
  'restaurant',
  'schedule',
  'timeadded',
  'type',
  'hasSupplier',
  'supplier',
  'transportType',
];
export const GALLERY = ['name', 'url', 'tags', 'timeuploaded'];
export const GUIDE = [
  'address',
  'dob',
  'email',
  'firstname',
  'languages',
  'lastname',
  'notes',
  'payment',
  'phonenumber',
  'prefferedlanguage',
  'skill',
  'status',
];
export const USER = [
  'agent',
  'commission',
  'deactivated',
  'dob',
  'email',
  'firstname',
  'lastname',
  'userid',
  'usertype',
  'registrationdate',
];
export const CART = ['cart', 'userid'];
export const ORDER = [
  'amount',
  'cancelled',
  'isprivate',
  'leadpassenger',
  'originalamount',
  'date',
  'ordered',
  'payed',
  'personcount',
  'ref',
  'tourid',
  'tour',
  'type',
  'user',
  'payment',
  'pickup',
  'idnumber',
];
export const COMMENT = ['comment', 'confirmed', 'user', 'tourid', 'timeadded'];
export const PICKUP = ['name', 'coordinate'];
export const ENTRANCES = [
  'address',
  'comment',
  'contactperson',
  'details',
  'email',
  'name',
  'payment',
  'phone',
  'price',
];
export const TRANSPORT = [
  'address',
  'notes',
  'payment',
  'price',
  'provider',
  'vat',
  'vehicle',
];
export const RESTAURANT = [
  'address',
  'manager',
  'menu',
  'name',
  'price',
  'vat',
  'venuecapacity',
];
