import * as types from 'constants/actions';

export const getUsers = () => ({
  type: types.GET_USERS,
});

export const setUsers = (users) => ({
  type: types.SET_USERS,
  users,
});

export const getCurrentUser = () => ({
  type: types.GET_CURRENT_USER,
});

export const setCurrentUser = (user) => ({
  type: types.SET_CURRENT_USER,
  user,
});

export const updateUser = (
  id,
  user,
  toUpdateEmail,
  toUpdatePassword,
  changedAdminInfo,
) => ({
  type: types.UPDATE_USER,
  id,
  user,
  toUpdateEmail,
  toUpdatePassword,
  changedAdminInfo,
});
