import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SignupForm from 'components/forms/SignupForm';
import { createUser } from '../services/auth/actions';
import { checkIsLoggedIn } from '../helpers/authentication';

export class Signup extends Component {
  state = {
    successfulLogin: null,
    verifiedEmail: null,
    isLoggingIn: null,
  };

  static getDerivedStateFromProps(
    { successfulLogin, isLoggingIn, verifiedEmail },
    prevState,
  ) {
    return {
      ...prevState,
      successfulLogin,
      isLoggingIn,
      verifiedEmail,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (checkIsLoggedIn()) {
      history.push('/');
    }
  }

  componentDidUpdate() {
    const { successfulLogin, verifiedEmail } = this.state;
    const { history } = this.props;

    if (verifiedEmail !== null) {
      if (!verifiedEmail) {
        history.push('/message/emailNotVerified');
        this.setState({ verifiedEmail: null });
      }
    }

    if (successfulLogin !== null) {
      if (successfulLogin) {
        this.setState({ successfulLogin: null });
        history.push('/');
      }
    }
  }

  createNewUser = (values) => {
    const { createUser } = this.props;
    createUser(values.email, values.password, values);
  };

  render() {
    const { isLoggingIn } = this.state;
    return (
      <SignupForm onSubmit={this.createNewUser} isLoggingIn={isLoggingIn} />
    );
  }
}

export default withRouter(
  connect(
    ({ authentication }) => ({
      successfulLogin: authentication.successfulLogin,
      isLoggingIn: authentication.isLoggingIn,
      verifiedEmail: authentication.verifiedEmail,
    }),
    {
      createUser,
    },
  )(Signup),
);
