import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import Firestore from 'firebase-service/Firestore';
import firebase from 'firebase/app';
import { success, error } from 'helpers/reactToaster';
import * as actions from './actions';

const firestore = new Firestore();

function* getTours() {
  const result = yield call(
    firestore.getRSF().firestore.getCollection,
    firestore.getCollection('tour'),
  );
  let tours = firestore.sanitizeSnapshot(result, fields.TOUR);

  tours = tours.filter(
    (tour) => !(tour.name === 'tour de malta') && tour.active === true,
  );

  yield put(actions.setTours(tours));
}

function* getSingleTour(id) {
  yield put(actions.setSingleTour(null));
  const result = yield call(
    firestore.getRSF().firestore.getDocument,
    `tour/${id.id}`,
  );
  const singleTour = firestore.sanitizeSingleSnapshot(result, fields.TOUR);

  yield put(actions.setSingleTour(singleTour));
}

function* updateTourOrder(value) {
  const increment = yield firebase.firestore.FieldValue.increment(1);
  yield call(
    firestore.getRSF().firestore.setDocument,
    `tour/${value.id}`,
    { orders: increment },
    { merge: true },
  );

  yield put(actions.getTours());
}

function* rateTour(value) {
  try {
    yield call(
      firestore.getRSF().firestore.setDocument,
      `tour/${value.id}`,
      { rate: value.rate },
      { merge: true },
    );
    success('Thanks! Rate is subjected for review');
  } catch (e) {
    console.log(e);
    error(e);
  }
}

function* toursSaga() {
  yield all([
    takeLatest(types.GET_TOURS, getTours),
    takeLatest(types.GET_SINGLE_TOUR, getSingleTour),
    takeLatest(types.UPDATE_TOUR_ORDER, updateTourOrder),
    takeLatest(types.RATE_TOUR, rateTour),
  ]);
}

export default toursSaga;
