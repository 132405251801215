import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Bullets from 'components/utils/Bullets';
import PickPointMap from 'components/maps/PickPointMap';
import ScrollAnimation from 'react-animate-on-scroll';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ImageViewer } from './ImageViewer';
import { formatTime } from '../../helpers/dateTime';

const General = (props) => {
  const { lightRoomImages, getRestaurantInfo, toggleMeals } = props;

  const { tour } = useSelector((state) => state.tours);
  const { entrances } = useSelector((state) => state.entrances);
  const { pickups } = useSelector((state) => state.pickups);

  const tourEntrances = () => {
    const tourEntrances = [];
    // eslint-disable-next-line no-unused-expressions
    tour &&
      tour.entrance &&
      entrances &&
      tour.entrance.map((tourEntrance) => {
        const tempTE = entrances.filter((e) => e.uuid === tourEntrance);
        if (tempTE.length > 0) tourEntrances.push(tempTE[0]);
        return tourEntrance;
      });
    return tourEntrances;
  };

  const tourPickupPoints = () => {
    const tourPickupPoints = [];
    // eslint-disable-next-line no-unused-expressions
    tour &&
      tour.pickups &&
      pickups &&
      tour.pickups.map((p) => {
        const tempPP = pickups.filter((e) => e.uuid === p.id);
        if (tempPP.length > 0) tourPickupPoints.push(tempPP);
        return p;
      });
    return tourPickupPoints;
  };

  const getPickupInfo = (id) =>
    pickups.filter((pickup) => pickup.uuid === id)[0];

  return (
    <div className="row">
      <div className="col">
        <ImageViewer images={lightRoomImages} />
        <div className="row section">
          <div className="col">
            <span className="fade-gray">{tour.type}</span>
            <br />
            <span className="fade-gray">
              {`${formatTime(tour.schedule.start)} - ${formatTime(
                tour.schedule.end,
              )}`}
            </span>
          </div>
        </div>
        <div className="row section">
          <div className="col">
            <span className="fade-gray-description">{tour.description}</span>
          </div>
        </div>
        <div className="row section">
          <div className="col">
            <h4>
              <label>PRICE INCLUDES</label>
            </h4>
          </div>
        </div>
        <div className="row section">
          <div className="col">
            {!_.isEmpty(tour.places) &&
              tour.places.map((place, i) => <Bullets key={i} label={place} />)}
          </div>
        </div>
        <hr />
        {tour &&
          getRestaurantInfo(tour.restaurant) &&
          getRestaurantInfo(tour.restaurant).menu && (
            <div>
              <ScrollAnimation animateIn="slideInLeft" animateOnce>
                <div className="row section">
                  <div className="col d-flex justify-content-between">
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon={faUtensils}
                        style={{ color: '#ce1011', fontSize: '40px' }}
                      />
                      <div className="row ml-3">
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <h4>
                                <label>RESTAURANT</label>
                                <span className="ml-3">(PRICE INCLUDES)</span>
                              </h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <span>
                                You can make a pre-order ( 2-3 Items) and enjoy
                                the meal
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: '40px', cursor: 'pointer' }}
                      onClick={() => toggleMeals(true)}
                    />
                  </div>
                </div>
              </ScrollAnimation>
              <hr />
            </div>
          )}
        {tour &&
        tour?.transportType &&
        tour?.transportType === 'no-transport' ? (
          <>
            <div className="row section">
              <div className="col">
                <h4>
                  <label>PRICE DOES NOT INCLUDE</label>
                </h4>
              </div>
            </div>
            <div className="row section">
              <div className="col">
                <Bullets label="Non local hotel transfers" />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <hr />
        <div className="row section">
          <div className="col">
            <h4>
              <label>REQUIREMENTS</label>
            </h4>
          </div>
        </div>
        <div className="row section">
          <div className="col">
            <Bullets label="Children must be accompanied by an adult" />
            <Bullets label="Refunds will not be issued if tour/activity is missed due to late or non-arrival of cruise" />
          </div>
        </div>
        {tour && tour?.languages && (
          <Fragment>
            <hr />
            <div className="row section">
              <div className="col">
                <h4>
                  <label>GUIDE LANGUAGE</label>
                </h4>
              </div>
            </div>
            <div className="row section">
              <div className="col">
                {tour?.languages?.map((language, i) => (
                  <Bullets label={language} key={i} />
                ))}
              </div>
            </div>
          </Fragment>
        )}
        {tourEntrances().length ? (
          <Fragment>
            <hr />
            <div className="row section">
              <div className="col">
                <h4>
                  <label>ENTRANCES</label>
                </h4>
              </div>
            </div>
            <div className="row section">
              <div className="col">
                {tourEntrances().map((te, i) => (
                  <Bullets key={i} label={te.name} />
                ))}
              </div>
            </div>
          </Fragment>
        ) : null}
        <hr />
        <div className="row section">
          <div className="col">
            <h4>
              <label>TRANSPORT</label>
            </h4>
          </div>
        </div>
        <div className="row section">
          <div className="col">
            {tour &&
              tour?.transportType &&
              tour?.transportType === 'no-transport' && (
                <Bullets label="No transport is available" />
              )}
            {tour &&
              tour?.transportType &&
              tour?.transportType === 'transport-included-in-price' && (
                <Bullets label="Transport is included in the price. You will be asked to select a pick-up point during checkout." />
              )}
            {tour &&
              tour?.transportType &&
              tour?.transportType === 'transport-optional' && (
                <Bullets label="Transport is optional. You will be asked to select a pick-up point during checkout." />
              )}
          </div>
        </div>
        {/* {tour && tour.transport && tour.pickups ? (
          <Fragment>
            <div className="row section">
              <div className="col">
                <h4>
                  <label>PICK UP POINTS</label>
                </h4>
              </div>
            </div>
            <div className="row section">
              <div className="col">
                {tour.pickups.map((pickup, i) => (
                  <Bullets
                    key={i}
                    label={(
                      <span>
                        <span>{`${
                          getPickupInfo(pickup.id)
                          && getPickupInfo(pickup.id).name
                        } - `}
                        </span>
                        <span className="theme-red">
                          {formatTime(pickup.time)}
                        </span>
                      </span>
                        )}
                  />
                ))}
              </div>
            </div>
            <div className="row section mb-0 pb-0">
              <div className="col mt-0 pt-0">
                <ScrollAnimation animateIn="zoomIn" animateOnce>
                  <PickPointMap pickups={tourPickupPoints()} />
                </ScrollAnimation>
              </div>
            </div>
          </Fragment>
        ) : (
          tour.message && (
            <Fragment>
              <div className="row section">
                <div className="col">
                  <h4>
                    <label>PICK UP POINTS</label>
                  </h4>
                </div>
              </div>
              <div className="message-box">
                <div className="row">
                  <div className="col">
                    <span className="fade-gray">
                      Message From Tour Operator:
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <span className="fade-gray">{tour.message}</span>
                  </div>
                </div>
              </div>
            </Fragment>
          )
        )} */}
      </div>
    </div>
  );
};

export default General;
