import React from 'react';
import { Link } from 'react-router-dom';
import { Rating } from 'primereact/rating';
import DragScroll from 'react-dragscroll';
import { IMAGE_DISPLAY } from '../../helpers/image';
import { ImageComponent } from '../utils/ImageComponent';

export default function Featured(props) {
  const {
    latestTours,
    filteredLatestTours,
    getImageUrl,
    getRating,
    getCommentsLength,
    checkEmptyFilters,
    renderSchedSwitch,
  } = props;
  return (
    <DragScroll className="horizontal-scroll">
      {(checkEmptyFilters() ? latestTours : filteredLatestTours)
        .filter((tour) => tour.featured)
        .slice(0, 6)
        .map((tour, i) => (
          <Link
            key={i}
            to={`/tour/${encodeURIComponent(tour.name)}-${tour.uuid}`}
          >
            <div className="tour-card-featured mr-3 animated fadeIn">
              <ImageComponent
                alt={tour.name}
                height={400}
                width={250}
                type={IMAGE_DISPLAY}
                image={getImageUrl(tour.displayimage || tour.photos[0])}
              />
              <div className="tour-desc">
                <div className="tour-desc-background" />
                <div className="row">
                  <div className="col">
                    <p className="tour-name">{tour.name}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <span style={{ fontSize: '12px' }} className="mt-3 mb-3">
                      {tour.pricing.tourtype === 'private'
                        ? 'Private'
                        : `from € ${Number(tour.pricing.price.adult).toFixed(
                            2,
                          )}`}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col">{renderSchedSwitch(tour)}</div>
                </div>
                {/* <div className="row">
                  <div className="col d-flex justify-content-between">
                    <Rating
                      value={getRating(
                        tour.rate
                          ? tour.rate.filter((rate) => rate.confirmed)
                          : false,
                      )}
                      stars={5}
                      readonly
                      cancel={false}
                    />
                    <span style={{ fontSize: '12px' }}>{`${getCommentsLength(
                      tour.uuid,
                    )} Reviews`}</span>
                  </div>
                </div> */}
              </div>
            </div>
          </Link>
        ))}
    </DragScroll>
  );
}
