import React, { useState, useEffect } from 'react';
import {Button} from 'primereact/button';
import { useSelector } from 'react-redux';

const BookFooter: React.FC = (props) => {
  const {page, disabled, paypalSuccess, agent, tourtype, isprivate, error, onPrevious} = props;
  const { loading } = useSelector(state => state.payments);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div className="row booking-footer">
      <div className="col-4 d-flex align-items-center">
        <span>Step</span>
        <span className="ml-3 font-weight-bold">{`${((tourtype === 'private') || isprivate) && page === 3 ? page - 1 : page}/${(((tourtype === 'private') || isprivate) && '2') || (agent && '3') || '4'}`}</span>
      </div>
      <div className="col-8">
        <div className="row">
          {
            page > 1 && !paypalSuccess && (
              <div className="col">
                <Button tooltip={error} label="PREVIOUS" className="prev-button" onClick={onPrevious} />
              </div>
            )
          }
          { page !== 4 && (
            <div className="col">
              <Button
                label={((isprivate && page === 3) && 'CONTINUE') || (paypalSuccess && 'CONTINUE') || (tourtype === 'private' && 'CONTINUE') || ((agent && page === 3) && 'CONTINUE') || (page === 4 ? 'PAY' : 'NEXT')} 
                className="next-button"
                type="submit"
                disabled={disabled}>
                {isLoading && <i className="pi pi-spin pi-spinner" />}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default BookFooter;
