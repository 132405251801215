import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter } from 'react-router-dom';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import ReduxToastr from 'react-redux-toastr';
import Main from 'container/Main';
import ScrollToTop from 'helpers/ScrollToTop';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import SimpleReactLightbox from 'simple-react-lightbox';
import reducers from './services/root-reducer';
import services from './services';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['payments', 'gallery'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const browserHistory = createBrowserHistory();
const middleware = routerMiddleware(browserHistory);
const sagaMiddleware = createSagaMiddleware();

// TODO Disable the react-redux plugin in prod
const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(middleware, sagaMiddleware)
    : compose(
        applyMiddleware(middleware, sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : (NOOP) => NOOP,
      ),
);

services.forEach((service) => sagaMiddleware.run(service));

const history = syncHistoryWithStore(browserHistory, store);
const persistor = persistStore(store);
render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <SimpleReactLightbox>
          <BrowserRouter history={history}>
            <ScrollToTop>
              <Main />
            </ScrollToTop>
          </BrowserRouter>
        </SimpleReactLightbox>
      </div>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
// persistor.purge(); // Removes redux data persist

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
