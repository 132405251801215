import React, { Component } from 'react';
import LoginForm from 'components/forms/LoginForm';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { success } from 'helpers/reactToaster';
import { loginUser, setVerifyStatus } from '../services/auth/actions';
import { checkIsLoggedIn } from '../helpers/authentication';

export class Login extends Component {
  state = {
    successfulLogin: null,
    verifiedEmail: null,
    isLoggingIn: null,
  };

  static getDerivedStateFromProps(
    { successfulLogin, isLoggingIn, verifiedEmail },
    prevState,
  ) {
    return {
      ...prevState,
      successfulLogin,
      isLoggingIn,
      verifiedEmail,
    };
  }

  componentDidMount() {
    const { setVerifyStatus, history } = this.props;
    setVerifyStatus(null);

    if (checkIsLoggedIn()) {
      history.push('/');
    }
  }

  componentDidUpdate() {
    const { successfulLogin, verifiedEmail } = this.state;
    const { history, location } = this.props;
    if (verifiedEmail !== null) {
      if (!verifiedEmail) {
        history.push('/message/emailNotVerified');
        this.setState({ verifiedEmail: null });
      }
    }

    if (successfulLogin !== null) {
      if (successfulLogin) {
        success('Logged in successfully');
        if (location.state !== undefined) {
          history.push(`${location.state.from}`);
          this.setState({ successfulLogin: null });
          return;
        }
        history.push('/');
        this.setState({ successfulLogin: null });
      }
    }
  }

  loginCurrentUser = (values) => {
    const { loginUser } = this.props;
    loginUser(values.email, values.password);
  };

  render() {
    const { isLoggingIn } = this.state;
    const { location } = this.props;
    return (
      <LoginForm onSubmit={this.loginCurrentUser} isLoggingIn={isLoggingIn} />
    );
  }
}

export default withRouter(
  connect(
    ({ authentication }) => ({
      successfulLogin: authentication.successfulLogin,
      isLoggingIn: authentication.isLoggingIn,
      verifiedEmail: authentication.verifiedEmail,
    }),
    {
      loginUser,
      setVerifyStatus,
    },
  )(Login),
);
