import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

function BookHeader(props) {
  const { page, title, isprivate, setIsprivate } = props;
  const goPrivate =
    'Want to book this tour without joining a group? Go Private and have this tour exclusively!';
  const gonePrivate =
    'You have activated Go Private! Enjoy this tour exclusively.';
  return (
    <div className="row book-header">
      <div className="col d-flex align-items-center">
        <span className="circle-icon mr-3">{page}</span>
        <label>{title}</label>
        {/* {
          page === 1 && (
            <div className="ml-auto d-flex align-items-center">
              <label className="mr-3 mt-2">Go Private</label>
              <InputSwitch tooltip={isprivate ? gonePrivate : goPrivate} checked={isprivate} onChange={e => setIsprivate(e.value)} />
            </div>
          )
        } */}
      </div>
    </div>
  );
}

export default BookHeader;
