import * as types from 'constants/actions';

const initState = {
  users: [],
  user: null,
};

export default (state = initState, action) => {
  const { user, users, type } = action;

  switch (type) {
    case types.SET_USERS:
      return { ...state, users };
    case types.SET_CURRENT_USER:
      return { ...state, user };
    default:
      return { ...state };
  }
};
