import * as types from 'constants/actions';

const initState = {
  tours: [],
  tour: null,
};

export default (state = initState, action) => {
  const { tour, tours, type } = action;

  switch (type) {
    case types.SET_TOURS:
      return { ...state, tours };
    case types.SET_SINGLE_TOUR:
      return { ...state, tour };
    default:
      return { ...state };
  }
};
