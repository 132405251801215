import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import { actionCodeSettings } from 'constants/actionCodeSettings';
import { success, error } from 'helpers/reactToaster';
import Cookies from 'js-cookie';
import 'firebase/firestore';
import Firestore from 'firebase-service/Firestore';
import { logoutUser } from 'helpers/authentication';
import axios from 'axios';
import * as actions from './actions';
import { getCurrentUser } from '../users/actions';
import { MALTATOURS_CONFIG } from '../../firebase-service/config';

const firestore = new Firestore();

function* loginUser(value) {
  try {
    yield put(actions.setIsLoggingStatus(true));
    const user = yield call(
      firestore.getRSF().auth.signInWithEmailAndPassword,
      value.email,
      value.password,
    );
    if (!user.user.emailVerified) {
      const data = {
        email: value.email,
        actionCodeSettings,
      };
      axios
        .post(`${MALTATOURS_CONFIG.cf}/sendVerificationEmail`, { data })
        .then((res) => res.status !== 200 && error(res.data));
      yield call(firestore.getRSF().auth.signOut);
      logoutUser();
      yield put(actions.setVerifyStatus(false));
      yield put(actions.setLoginStatus(false));
    } else {
      let users = yield call(
        firestore.getRSF().firestore.getCollection,
        firestore.get().collection('users').where('email', '==', value.email),
      );
      users = firestore.sanitizeSnapshot(users, fields.USER);

      if (users.length > 0) {
        const currentUser = users[0];

        if (currentUser.deactivated) {
          error(
            'Your account has been deactivated. Please contact admin to activate.',
          );
          yield put(actions.setLoginStatus(false));
        } else {
          yield Cookies.set('user', user.user.uid, { expires: 7 });
          yield put(actions.setLoginStatus(true));
          yield put(actions.setVerifyStatus(true));
          yield put(getCurrentUser());
        }
      }
    }
    yield put(actions.setIsLoggingStatus(false));
  } catch (e) {
    yield put(actions.setLoginStatus(false));
    yield put(actions.setIsLoggingStatus(false));
    if (e.code === 'auth/user-not-found') {
      error('Email is not yet registered.');
    } else if (e.code === 'auth/wrong-password') {
      error('Incorrect password');
    } else {
      error(e.message);
    }
    console.log(e);
  }
}

function* createUser(value) {
  try {
    yield put(actions.setIsLoggingStatus(true));
    const result = yield call(
      firestore.getRSF().auth.createUserWithEmailAndPassword,
      value.email,
      value.password,
      value.user,
    );
    const user = {
      agent: false,
      email: value.email,
      firstname: value.user.firstname,
      lastname: value.user.lastname,
      userid: result.user.uid,
      usertype: 'customer',
      registrationdate: new Date().toLocaleDateString('en-gb', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
    };

    yield call(
      firestore.getRSF().firestore.setDocument,
      `users/${result.user.uid}`,
      user,
    );
    yield put(actions.loginUser(value.email, value.password));
  } catch (e) {
    yield put(actions.setIsLoggingStatus(false));
    console.log(e);
    error(e.message);
  }
}

function* logout() {
  try {
    yield call(firestore.getRSF().auth.signOut);
    yield Cookies.remove('user');
    success('Successfully logged out');
    window.location.reload();
  } catch (e) {
    console.log(e);
    error(e.message);
  }
}

function* deleteProfile() {
  try {
    yield call(
      firestore.getRSF().firestore.deleteDocument,
      `users/${Cookies.get('user')}`,
    );
    yield call(firestore.getRSF().auth.deleteProfile);
    yield Cookies.remove('user');
    success('Your account has been successfully deleted');
    window.location.reload();
  } catch (e) {
    console.log(e);
    error(e.message);
  }
}

function* sendResetPasswordEmail(value) {
  try {
    console.log(actionCodeSettings);
    yield call(
      firestore.getRSF().auth.sendPasswordResetEmail,
      value.email,
      actionCodeSettings,
    );
    success('Email Sent! Please check your email');
  } catch (e) {
    console.log(e);
    error(e.message);
  }
}

function* resetPassword(value) {
  try {
    yield call(
      firestore.getRSF().auth.confirmPasswordReset,
      value.code,
      value.user.password,
    );
    yield put(actions.loginUser(value.user.email, value.user.password));
    success('Password successfully updated');
  } catch (e) {
    console.log(e);
    error(e.message);
  }
}

function* updateEmail(value) {
  try {
    yield call(
      firestore.getRSF().auth.signInWithEmailAndPassword,
      value.oldemail,
      value.password,
    );
    yield call(firestore.getRSF().auth.updateEmail, value.newemail);
    success('Email successfully updated');
  } catch (e) {
    error(e);
    console.log(e);
  }
}

function* updatePassword(value) {
  try {
    yield call(
      firestore.getRSF().auth.signInWithEmailAndPassword,
      value.email,
      value.oldpassword,
    );
    yield call(firestore.getRSF().auth.updatePassword, value.newpassword);
    success('Password has been updated');
  } catch (e) {
    error(e);
    console.log(e);
  }
}

function* authenticationSaga() {
  yield all([
    takeLatest(types.CREATE_USER, createUser),
    takeLatest(types.LOGIN_USER, loginUser),
    takeLatest(types.LOGOUT, logout),
    takeLatest(types.SEND_RESET_PASSWORD_EMAIL, sendResetPasswordEmail),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeLatest(types.UPDATE_EMAIL, updateEmail),
    takeLatest(types.UPDATE_PASSWORD, updatePassword),
    takeLatest(types.DELETE_PROFILE, deleteProfile),
  ]);
}

export default authenticationSaga;
