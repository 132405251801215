/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import Filter from 'components/utils/Filter';
import General from 'components/tour-displays/General';
import { windowHeight } from 'constants/height';

import { getImages } from '../services/gallery/actions';
import { getComments } from '../services/comments/actions';
import { getTours } from '../services/tours/actions';
import { getDays } from '../helpers/dateTime';

export class Tours extends Component {
  state = {
    filteredLatestTours: [],
    filterName: '',
    filterDates: null,
    filterPrice: [0, 0],
    filterCount: 0,
    filterPlaces: [],
  };

  getImageUrl = (id) => {
    const { images } = this.props;
    const temp =
      images.length > 0 ? images.filter((image) => image.uuid === id)[0] : null;
    return temp;
  };

  getMillis = (time) => (time ? time.toDate() : null);

  checkEmptyFilters = () => {
    const { filterName, filterDates, filterPrice, filterPlaces } = this.state;
    if (
      filterName === '' &&
      (filterDates === null || filterDates === '') &&
      filterPrice[1] === 0 &&
      filterPlaces.length === 0
    ) {
      return true;
    }
    return false;
  };

  checkEmptyName = () => {
    const { filterName } = this.state;
    return filterName === '';
  };

  checkEmptyDates = () => {
    const { filterDates } = this.state;
    return filterDates === null;
  };

  checkEmptyPrice = () => {
    const { filterPrice } = this.state;
    return filterPrice[1] === 0;
  };

  checkEmptyPlaces = () => {
    const { filterPlaces } = this.state;
    return filterPlaces.length === 0;
  };

  filterToursByName = (e, values) => {
    const { filterDates } = this.state;
    let tempTours = values;
    this.setState({ filterName: e.target.value }, () => {
      tempTours = this.filterName(tempTours);

      if (!this.checkEmptyDates()) {
        tempTours = this.filterDate(filterDates, tempTours);
      }
      if (!this.checkEmptyPrice()) {
        tempTours = this.filterPrice(tempTours);
      }
      if (!this.checkEmptyPlaces()) {
        tempTours = this.filterPlace(tempTours);
      }
      this.setState({ filteredLatestTours: tempTours });
      this.filterCount();
    });
  };

  filterName = (tours) => {
    const { filterName } = this.state;
    const tempTours = tours.filter(
      (value) =>
        value.name.toLowerCase().search(filterName.toLowerCase()) !== -1,
    );
    return tempTours;
  };

  filterToursByDate = (e, values) => {
    let tempTours;

    this.setState({ filterDates: e }, () => {
      tempTours = this.filterDate(e, values);

      if (!this.checkEmptyName()) {
        tempTours = this.filterName(tempTours);
      }
      if (!this.checkEmptyPrice()) {
        tempTours = this.filterPrice(tempTours);
      }
      if (!this.checkEmptyPlaces()) {
        tempTours = this.filterPlace(tempTours);
      }
      this.setState({ filteredLatestTours: tempTours });
      this.filterCount();
    });
  };

  filterDate = (e, tours) => {
    let tempTours = tours;
    if (e !== null || e !== undefined) {
      if (e && e !== '') {
        tempTours = tours.filter((value) =>
          this.checkDateWithinRange(value.schedule),
        );
      }
    }
    return tempTours;
  };

  checkDateWithinRange = (schedule) => {
    const { filterDates } = this.state;
    let withinRange = false;

    switch (schedule.type) {
      case 'Everyday':
        withinRange = true;
        break;
      case 'Every Week':
        if (filterDates[1]) {
          const allDatesInRange = getDays(filterDates[0], filterDates[1]);
          if (allDatesInRange.length >= 7) {
            withinRange = true;
          } else {
            const daysOfWeeks = [];
            for (let i = 0; i < allDatesInRange.length; i++) {
              daysOfWeeks.push(moment(allDatesInRange[i]).format('dddd'));
            }
            schedule.days.forEach((day) => {
              if (daysOfWeeks.includes(day)) {
                withinRange = true;
              }
            });
          }
        }

        if (!filterDates[1]) {
          schedule.days.forEach((day) => {
            const singleSelectedDay = moment(filterDates[0]).format('dddd');
            const isDaySame = day === singleSelectedDay;
            if (isDaySame) {
              withinRange = true;
            }
          });
        }
        break;
      case 'Specific Dates':
        schedule.dates.forEach((date) => {
          const seconds = _.get(date, 'seconds');
          const formattedDateSchedule = moment.unix(seconds).toDate();

          const isSameWithFirstDateRange = moment(formattedDateSchedule).isSame(
            filterDates[0],
            'day',
          );
          const isSameWithLastDateRange =
            moment(formattedDateSchedule).isSame(filterDates[1], 'day') ||
            false;
          const isBetween = moment(formattedDateSchedule).isBetween(
            filterDates[0],
            filterDates[1],
          );

          if (
            isSameWithFirstDateRange ||
            isSameWithLastDateRange ||
            isBetween
          ) {
            withinRange = true;
          }
        });
        break;
      default:
        withinRange = false;
    }
    return withinRange;
  };

  filterToursByPrice = (e, values) => {
    const { filterDates } = this.state;
    let tempTours = values;

    this.setState({ filterPrice: e }, () => {
      if (filterDates !== [0, 0]) {
        tempTours = this.filterPrice(tempTours);
      }

      if (!this.checkEmptyName()) {
        tempTours = this.filterName(tempTours);
      }
      if (!this.checkEmptyDates()) {
        tempTours = this.filterDate(filterDates, tempTours);
      }
      if (!this.checkEmptyPlaces()) {
        tempTours = this.filterPlace(tempTours);
      }
      this.setState({ filteredLatestTours: tempTours });
      this.filterCount();
    });
  };

  filterPrice = (tours) => {
    const { filterPrice } = this.state;
    let tempTours;
    if (filterPrice[2] !== 0) {
      tempTours = tours.filter((value) =>
        this.checkPriceWithinRange(value.pricing.price),
      );
    } else tempTours = tours;

    return tempTours;
  };

  filterToursByPlace = (e, values) => {
    let tempTours = values;
    const { filterDates, filterPlaces } = this.state;
    const selectedPlaces = [...filterPlaces];
    if (e.checked) {
      selectedPlaces.push(e.value);
    } else {
      selectedPlaces.splice(selectedPlaces.indexOf(e.value), 1);
    }

    this.setState({ filterPlaces: selectedPlaces }, () => {
      tempTours = this.filterPlace(values);

      if (!this.checkEmptyName()) {
        tempTours = this.filterName(tempTours);
      }
      if (!this.checkEmptyDates()) {
        tempTours = this.filterDate(filterDates, tempTours);
      }
      if (!this.checkEmptyPrice()) {
        tempTours = this.filterPrice(tempTours);
      }
      this.setState({ filteredLatestTours: tempTours });
      this.filterCount();
    });
  };

  filterPlace = (tours) => {
    const { filterPlaces } = this.state;
    let tempTours;
    if (filterPlaces.length > 0) {
      tempTours = tours.filter((value) =>
        this.hasSameElement(value.places, filterPlaces),
      );
    } else tempTours = tours;
    return tempTours;
  };

  hasSameElement = (places, selectedPlaces) => {
    let hasSameElement = false;

    selectedPlaces.forEach((selectedPlace) => {
      if (places.includes(selectedPlace)) {
        hasSameElement = true;
      }
    });

    return hasSameElement;
  };

  checkPriceWithinRange = (price) => {
    const { filterPrice } = this.state;

    if (
      filterPrice[0] <= Number(price && price.adult) &&
      filterPrice[1] >= Number(price && price.adult)
    ) {
      return true;
    }
    return false;
  };

  resetFilters = () => {
    this.setState({
      filterName: '',
      filterDates: null,
      filterPrice: [0, 0],
      filterPlaces: [],
      filterCount: 0,
    });
  };

  renderSchedSwitch = (tour) => {
    // const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    // const text = [];
    switch (tour.schedule.type) {
      case 'Everyday':
        return `Everyday ${tour.schedule.start} - ${tour.schedule.end}`;

      case 'Every Week':
        return tour.schedule.days.map(
          (day, i) =>
            `${day}s ${
              tour.schedule.days.length - 2 === i
                ? ' and '
                : tour.schedule.days.length - 2 > i
                  ? ', '
                  : ''
            }`,
        );

      case 'Specific Dates':
        // for (let i = 0; i < tour.schedule.dates.length; i++) {
        //   text.push(`${i === 0 ? `${months[tour.schedule.dates[i].toDate().getMonth()]} ${tour.schedule.dates[i].toDate().getDate()}`
        //     : (tour.schedule.dates[i].toDate().getMonth() === tour.schedule.dates[i - 1].toDate().getMonth() ? `${tour.schedule.dates[i].toDate().getDate()}` : `${months[tour.schedule.dates[i].toDate().getMonth()]} ${tour.schedule.dates[i].toDate().getDate()}`)}`);

        //   text.push(i === (tour.schedule.dates.length - 2) ? ' and ' : (i < (tour.schedule.dates.length - 2) ? ', ' : ''));
        // }
        return 'This tour is available on specific dates';

      default:
        return null;
    }
  };

  getCommentsLength = (id) => {
    const { comments } = this.props;
    return (
      comments && comments.filter((comment) => comment.tourid === id).length
    );
  };

  getRating = (rating) => {
    let rate = 0;
    if (rating) {
      rating.forEach((rateItem) => {
        rate += rateItem.rate;
      });
      rate /= rating.length;
    } else {
      rate = 0;
    }
    return rate;
  };

  renderPricing = (price) => {
    const priceText = [];
    for (let i = 0; i < price.length; i++) {
      priceText.push(
        `${price[i].from} to ${price[i].to} Persons: € ${price[i].price}`,
      );
    }
    return priceText.map((price, i) => (
      <span key={i}>
        {price}
        <br />
      </span>
    ));
  };

  filterCount = () => {
    let count = 0;
    const { filterName, filterDates, filterPrice, filterPlaces } = this.state;

    if (filterName.length > 0) count++;
    if (filterDates) count++;
    if (filterPrice[0] !== 0 || filterPrice[1] !== 0) count++;
    count += filterPlaces.length;

    this.setState({ filterCount: count });
  };

  getPopularPlaces = () => {
    const { tours } = this.props;
    const popularPlaces = [];

    // eslint-disable-next-line no-unused-expressions
    tours &&
      tours.forEach((tour) => {
        // eslint-disable-next-line no-unused-expressions
        tour.places &&
          tour.places.forEach((place) => {
            const hasValue = popularPlaces.filter(
              (popularPlace) => popularPlace.place === place,
            ).length;
            if (hasValue > 0) {
              popularPlaces.forEach((item) => {
                if (item.place === place) {
                  item.count += 1;
                }
              });
            } else {
              popularPlaces.push({ place, count: 1 });
            }
          });
      });

    return popularPlaces.sort((a, b) => b.count - a.count).slice(0, 5);
  };

  render() {
    const { tours, featured } = this.props;
    let latestTours = [...tours];
    latestTours = featured
      ? [...latestTours.filter((tour) => tour.featured)]
      : [...tours];
    const {
      filterPlaces,
      filteredLatestTours,
      filterName,
      filterDates,
      filterPrice,
      filterCount,
    } = this.state;

    return (
      <div
        className="container"
        style={{ position: 'relative', minHeight: windowHeight + 30 }}
      >
        <Filter
          latestTours={latestTours}
          filterPlaces={filterPlaces}
          filterPrice={filterPrice}
          filterName={filterName}
          filterDates={filterDates}
          filterCount={filterCount}
          filterToursByPlace={this.filterToursByPlace}
          filterToursByPrice={this.filterToursByPrice}
          filterToursByName={this.filterToursByName}
          filterToursByDate={this.filterToursByDate}
          resetFilters={() => this.resetFilters()}
          getPopularPlaces={() => this.getPopularPlaces()}
        />
        <div className="row" style={{ marginTop: '40px' }}>
          <div className="col d-flex justify-content-between section-header">
            <h2>
              {featured && <label className="mr-2">Featured</label>}
              <label>Tours</label>
            </h2>
          </div>
        </div>
        <General
          featured={featured}
          latestTours={latestTours}
          renderSchedSwitch={this.renderSchedSwitch}
          filteredLatestTours={filteredLatestTours}
          getImageUrl={this.getImageUrl}
          getRating={this.getRating}
          getCommentsLength={this.getCommentsLength}
          getMillis={this.getMillis}
          checkEmptyFilters={() => this.checkEmptyFilters()}
        />
      </div>
    );
  }
}

export default connect(
  ({ tours, gallery, comments }) => ({
    tours: tours.tours,
    images: gallery.images,
    comments: comments.comments,
  }),
  {
    getTours,
    getImages,
    getComments,
  },
)(Tours);
