// STAGING
const MALTATOUR_STAGE = {
  apiKey: 'AIzaSyDe4IQ5sRt2rEDVdJujvr4vpkRM4PT8pJM',
  authDomain: 'maltatour-stage-a1f43.firebaseapp.com',
  databaseURL: 'https://maltatour-stage-a1f43.firebaseio.com',
  projectId: 'maltatour-stage-a1f43',
  storageBucket: 'maltatour-stage-a1f43.appspot.com',
  messagingSenderId: '421284393680',
  appId: '1:421284393680:web:2bfa05c87288d41d',
};

// PROD
const MALTATOUR_PROD = {
  apiKey: 'AIzaSyCsX2JK1xBoXFEvwotHgexm10gnfvwtjBQ',
  authDomain: 'maltatour-prod.firebaseapp.com',
  projectId: 'maltatour-prod',
  storageBucket: 'maltatour-prod.appspot.com',
  messagingSenderId: '1005932604047',
  appId: '1:1005932604047:web:bd5c8581b3d2c78870defa',
  measurementId: 'G-R6KEQF6MY8',
};

// PROD
const WEB_CONFIG_PROD = {
  site: 'https://maltatour.web.app/login',
  cf: 'https://us-central1-maltatour-prod.cloudfunctions.net',
};

// STAGING
const WEB_CONFIG_STAGE = {
  site: 'https://maltatour-stage-a1f43-public.web.app/login',
  cf: 'https://us-central1-maltatour-stage-a1f43.cloudfunctions.net',
};

export const MALTATOUR_LOGO_STAGE =
  'https://firebasestorage.googleapis.com/v0/b/maltatour-stage-a1f43.appspot.com/o/original%20with%20reg_1500x1500.png?alt=media&token=39a5894b-1445-4141-a88f-88a1ebe7b67c';
export const MALTATOUR_LOGO_PROD =
  'https://firebasestorage.googleapis.com/v0/b/maltatour-prod.appspot.com/o/original%20with%20reg_1500x1500.png?alt=media&token=57e1d29f-b116-41ff-9ed2-22c58cc6bfa8';

export const MALTATOUR_LOGO = MALTATOUR_LOGO_PROD; // CHANGE PROD OR STAGE
export const MALTATOURS_CONFIG = WEB_CONFIG_PROD; // CHANGE PROD OR STAGE
export default MALTATOUR_PROD; // CHANGE PROD OR STAGE
