import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  dropdownField,
  textField,
  textAreaField,
  phoneField,
} from 'helpers/reduxForms';
import BookHeader from 'components/forms/booking/BookHeader';
import BookFooter from 'components/forms/booking/BookFooter';
import AlertMessage from 'components/utils/AlertMessage';
import { ScrollPanel } from 'primereact/scrollpanel';

export class ProvideDataForm extends Component {
  setFirstname = (value) => {
    const { setFirstname } = this.props;
    const firstname = value
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setFirstname(firstname);

    return firstname;
  };

  setLastname = (value) => {
    const { setLastname } = this.props;
    const lastname = value
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setLastname(lastname);

    return lastname;
  };

  setPhone = (value) => {
    const { setPhone } = this.props;
    setPhone(value);
    return value;
  };

  setEmail = (value) => {
    const { setEmail } = this.props;
    setEmail(value);
    return value;
  };

  setPickup = (value) => {
    const { setPickup } = this.props;
    setPickup(value);
    return value;
  };

  setLanguage = (value) => {
    const { setLanguage } = this.props;
    setLanguage(value);
    return value;
  };

  onSubmit = (values) => {
    const { onSubmit, payment, destroy, agent, price, isprivate } = this.props;
    values.payment = payment;

    onSubmit(values);

    if (agent || price.tourtype === 'private') {
      destroy();
    }
  };

  getDisplayPickups = () => {
    const { pickups, tourPickups } = this.props;
    const pickupOptions = [];

    // eslint-disable-next-line no-unused-expressions
    tourPickups &&
      tourPickups.forEach((tourPickup) => {
        const displayPickup = pickups.find(
          (pickup) => tourPickup.id === pickup.uuid,
        );

        if (displayPickup) {
          pickupOptions.push({
            label: `${tourPickup.time} - ${displayPickup.name}`,
            value: displayPickup.uuid,
            key: displayPickup.uuid,
          });
        }
      });

    return pickupOptions.sort((a, b) => a.label.localeCompare(b.label));
  };

  getLanguage = () => {
    const { language } = this.props;
    const languageSelected = [];
    const languages = [
      'Arabic',
      'Bulgarian',
      'Chinese',
      'Czech',
      'Dutch',
      'English',
      'French',
      'Finnish',
      'German',
      'Hungarian',
      'Italian',
      'Japanese',
      'Maltese',
      'Mandarin',
      'Norwegian',
      'Polish',
      'Portuguese',
      'Russian',
      'Spanish',
      'Swedish',
    ];
    language.forEach((lang) => {
      languageSelected.push({
        label: `${lang}`,
        value: lang,
        key: lang,
      });
    });
    return languageSelected;
  };

  render() {
    const {
      handleSubmit,
      agent,
      price,
      isprivate,
      onPrevious,
      firstname,
      lastname,
      phone,
      email,
      validateEmail,
      transportType,
      pickup,
      language,
    } = this.props;
    // const {fullname, extension, phone, email} = this.state;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <BookHeader
          page={price.tourtype === 'private' || isprivate ? 2 : 3}
          title="Provide Your Data"
        />
        <ScrollPanel style={{ maxHeight: '500px' }}>
          <div className="row" style={{ padding: '10px 50px' }}>
            <div className="col gray-form">
              <div className="row section">
                <div className="col">
                  <div className="row d-flex">
                    <div className="col-lg-6">
                      <Field
                        name="firstname"
                        label={agent ? "Customer's First Name" : 'First Name'}
                        component={textField}
                        normalize={this.setFirstname}
                      />
                      {!firstname && (
                        <AlertMessage message="First Name is required" />
                      )}
                    </div>
                    <div className="col-lg-6">
                      <Field
                        name="lastname"
                        label={agent ? "Customer's Last Name" : 'Last Name'}
                        component={textField}
                        normalize={this.setLastname}
                      />
                      {!lastname && (
                        <AlertMessage message="Last Name is required" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row section">
                <div className="col">
                  <div className="row d-flex">
                    <div className="col-lg-6">
                      <Field
                        name="phone"
                        component={phoneField}
                        label="Phone Number"
                        normalize={this.setPhone}
                      />
                      {!phone && (
                        <AlertMessage message="Phone Number is required" />
                      )}
                    </div>
                    <div className="col-lg-6">
                      <Field
                        name="email"
                        label="Email"
                        component={textField}
                        normalize={this.setEmail}
                      />
                      <br />
                      {!email ? (
                        <AlertMessage message="Email is required" />
                      ) : (
                        !validateEmail(email) && (
                          <AlertMessage message="Valid email format is required" />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {transportType !== 'no-transport' && (
                <div className="row section">
                  <div className="col">
                    <div>
                      Transport{' '}
                      {transportType === 'transport-optional' && (
                        <span> (optional)</span>
                      )}
                    </div>
                    <Field
                      name="pickup"
                      component={dropdownField}
                      options={this.getDisplayPickups()}
                      placeholder="Select a Pickup Location"
                      normalize={this.setPickup}
                    />
                    {!pickup &&
                      transportType === 'transport-included-in-price' && (
                        <AlertMessage message="Pick-up is required" />
                      )}
                  </div>
                </div>
              )}
              <div className="row section">
                <div className="col">
                  <div>Language</div>
                  <Field
                    name="language"
                    component={dropdownField}
                    options={this.getLanguage()}
                    placeholder="Select a language"
                    normalize={this.setLanguage}
                  />
                  {!language && <AlertMessage message="Language is required" />}
                </div>
              </div>
              <div className="row section">
                <div className="col">
                  <Field
                    name="request"
                    label="Special Requests"
                    placeholder="Enter your special wishes. Their implementation is not guaranteed, but we will try to fulfill your request"
                    component={textAreaField}
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollPanel>
        <BookFooter
          page={3}
          onPrevious={onPrevious}
          disabled={
            !firstname ||
            !lastname ||
            !phone ||
            !email ||
            !validateEmail(email) ||
            (!pickup && transportType === 'transport-included-in-price')
          }
          agent={agent}
          tourtype={price.tourtype}
          isprivate={isprivate}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'booking', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ProvideDataForm);
