import React, { useEffect, useState } from 'react';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import DragScroll from 'react-dragscroll';
import { useParams } from 'react-router';
import {
  IMAGE_DISPLAY,
  IMAGE_THUMBNAIL,
  prefixImage,
} from '../../helpers/image';
import placeholder from '../../images/placeholder.png';

export const ImageViewerComponent = ({ item }) => {
  const [imageDisplay, setDisplayImage] = useState(undefined);
  const [thumbnails, setThumbnails] = useState(undefined);

  const [error, hasError] = useState(false);
  const [displayImageError, setDisplayImageError] = useState(false);

  const onThumbnailError = (e) => {
    if (!error) {
      setThumbnails(placeholder);
      hasError(true);
    }
  };

  const onDisplayError = (e) => {
    if (!displayImageError) {
      setDisplayImage(placeholder);
      setDisplayImageError(true);
    }
  };

  useEffect(() => {
    if (item) {
      setThumbnails(prefixImage(item, IMAGE_THUMBNAIL));
      setDisplayImage(prefixImage(item, IMAGE_DISPLAY));
    }
  }, [item]);
  return (
    <a
      href={imageDisplay}
      alt=""
      className="SRLCustomCaption"
      onError={onDisplayError}
    >
      <img
        src={!error ? thumbnails : placeholder}
        alt=""
        className="SRLCustomCaption"
        onError={onThumbnailError}
      />
    </a>
  );
};

export const ImageViewer = ({ images }) => {
  // const [imagelist, setImagelist] = useState([]);
  const { tourId } = useParams();
  const { openLightbox } = useLightbox();

  const handleImageClick = (i) => {
    openLightbox(i);
  };

  // const handleImages = index => (image) => {
  //   setImagelist([...imagelist, {index, src: image}]);
  // };

  // const lightRoomImages = images.map((i, index) => {
  //   const findImage = imagelist.find(f => f.index === index);
  //   return { src: findImage?.src || i?.url, alt: i?.name, key: i?.uuid};
  // });

  const options = {
    settings: {
      autoplaySpeed: 0,
      disableWheelControls: true,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
    },
  };
  return (
    <div key={tourId} className="row">
      <DragScroll className="horizontal-scroll">
        <SRLWrapper options={options}>
          {images?.map((item, index) => (
            <div
              key={index.toString()}
              className="SRLCustomCaption viewer-img-item"
              onClick={() => handleImageClick(index)}
              onKeyDown={() => {}}
            >
              <ImageViewerComponent item={item} />
            </div>
          ))}
        </SRLWrapper>
      </DragScroll>
      {/* <div key={tourId}>
        <ReactViewer
          key={tourId}
          visible={visible}
          activeIndex={activeIndex}
          onClose={handleCloseBtn}
          onMaskClick={handleCloseBtn}
          images={imagelist ? lightRoomImages : []}
          rotatable={false}
          scalable={false}
          noImgDetails
          imagePr
          defaultImg={{ src: placeholder }}
          customImgNode={customImgNode}
        />
      </div> */}
    </div>
  );
};
